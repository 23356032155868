import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { gsap } from 'gsap'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

interface SlidesProps {
  active?: boolean
  title?: string
  image?: ImageProps
}
export interface Props {
  slides: SlidesProps[]
  cta?: ButtonProps
}

export const GalleryRooms = memo(function GalleryRooms({ slides, cta }: Props) {
  const [details, setDetails] = useState<any>(null)

  function positionStyle(idx: any) {
    if (!details || !details.slides || !details.slides[idx]) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1800,
    },
    loop: slides.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
      let index = s.track.details.rel
      let ctx = gsap.context(() => {
        gsap.killTweensOf('.slide-text')
        gsap.to('.slide-text', { opacity: 0, duration: 0.2 })
        gsap.to('.slide-text--' + index, {
          opacity: 1,
          duration: 0.2,
          delay: 0.4,
        })
      }, sliderText)
    },
    detailsChanged(s) {
      slides.length > 1 ? setDetails(s.track.details) : null
    },
  })

  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      galleryRef.current?.next()
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [galleryRef])

  const sliderText = useRef<HTMLDivElement>(null)

  return (
    <Container>
      <FadeInUp>
        <Carousel className="item-carousel" ref={sliderRef}>
          {uniq(slides).map((item, index) => (
            <ParallaxProvider>
              <Parallax className="item-parallax" translateY={['-15%', '15%']}>
                <Slide
                  key={index}
                  className={
                    currentSlide === index
                      ? 'keen-slider__slide active'
                      : 'keen-slider__slide'
                  }
                >
                  <Inner style={positionStyle(index)}>
                    <StyledImage {...item.image} />
                  </Inner>
                </Slide>
              </Parallax>
            </ParallaxProvider>
          ))}

          <Arrows>
            <Arrow
              direction="R"
              onClick={(e) => e.stopPropagation() || galleryRef.current?.next()}
            />
            <Arrow
              onClick={(e) => e.stopPropagation() || galleryRef.current?.prev()}
            />
          </Arrows>
        </Carousel>
        <SliderText ref={sliderText}>
          {slides.map((item, index) => (
            <SlideText
              key={index}
              className={`slide-text slide-text--${index}`}
            >
              {item.title}
            </SlideText>
          ))}
        </SliderText>

        {cta ? <StyledButton variant="default-no-bg-hover" {...cta} /> : null}
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  position: relative;

  .keen-slider__slide:before {
    content: '';
    width: 100%;
    height: 50%;
    backface-visibility: hidden;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.5)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    transition: 0.25s ease-in-out;
  }

  picture {
    height: 100%;
  }

  @media (max-width: 991px) {
    margin-bottom: 80px;
    margin-top: -50px;
  }
`

const Arrows = styled.div`
  position: absolute;
  top: 50%;
  right: 8vw;
  transform: translateY(-50%);
  z-index: 3;

  > div {
    margin: 20px 0;
  }

  @media (max-width: 991px) {
    right: 30px;
  }
`

const StyledImage = styled(Image)``

const SliderText = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 10vw;
  left: 10vw;

  @media (max-width: 991px) {
    top: 30px;
    left: 30px;
    bottom: auto;
  }
`
const SlideText = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  white-space: nowrap;
  font-size: 1vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.subheading};

  &:first-child {
    opacity: 1;
  }

  @media (max-width: 1439px) {
    font-size: 14px;
  }

  @media (max-width: 991px) {
    top: 0;
    bottom: auto;
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 10vw;
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  @media (max-width: 991px) {
    left: 0px;
    bottom: 0px;
    width: 100%;
    position: static;
    max-width: 100%;
    transform: none;
  }
`

const Carousel = styled.div`
  top: 0;
  left: 0;
  display: flex;
  position: relative;
  width: 100%;
  height: 45vw;
  overflow: hidden;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  @media (max-width: 991px) {
    height: 70vw;
  }
`

const Slide = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
    transition: 1.5s;
  }

  &.active {
    img {
      transition: 1.5s;
    }
  }
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
`
