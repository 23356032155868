import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

interface ImagesProps {
  image?: ImageProps
}
export interface Props {
  images?: ImagesProps[]
  title?: string
  ctaList?: ButtonProps[]
}

export const BlockBar = memo(function BlockBar({
  images,
  title,
  ctaList,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Inner>
          <Deco />

          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>

          <ImagesWrapper>
            <div>
              {images ? (
                <FadeInUp>
                  <StyledImage {...images[0].image} />
                </FadeInUp>
              ) : null}
            </div>
            <div>
              {images ? (
                <FadeInUp delay={0.15}>
                  <StyledImage {...images[1].image} />
                </FadeInUp>
              ) : null}
            </div>
          </ImagesWrapper>
          {ctaList ? (
            <ButtonsList>
              {ctaList.map((cta, index) => (
                <FadeInUp key={index}>
                  <React.Fragment key={index}>
                    <StyledButton variant="outline" {...cta} />
                  </React.Fragment>
                </FadeInUp>
              ))}
            </ButtonsList>
          ) : null}
        </Inner>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  margin: 8vw 0;

  @media (max-width: 991px) {
    margin: 60px 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding: 0 10vw;

  @media (max-width: 991px) {
    padding: 0px;
  }
`

const Inner = styled.div`
  position: relative;
  padding: 0 8vw;

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const Deco = styled.div`
  position: absolute;
  top: 50%;
  right: 2vw;
  width: 13vw;
  height: 120%;
  background-color: #ffffff;
  transform: translate3d(0, -50%, 0);

  @media (max-width: 991px) {
    right: 10px;
    height: 100%;
    width: 60px;
  }
`

const Title = styled.h3`
  top: 0;
  left: 0;
  white-space: nowrap;
  font-size: 2vw;
  position: absolute;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-weight: 200;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 16px;
    left: 10px;
    top: 30px;
  }
`

const ImagesWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 4vw;
  border-bottom: 1px solid ${({ theme }) => theme.colors.variants.neutralLight4};

  @media (max-width: 991px) {
    padding-bottom: 20px;
  }

  > div {
    width: 50%;
    display: flex;
    padding-right: 0;
    padding-left: 1.5vw;

    &:first-child {
      padding-right: 1.5vw;
      padding-left: 0;
    }
  }
`

const StyledImage = styled(Image)`
  height: auto;
  text-align: center;

  img {
    width: 100%;
    height: auto;
    display: inline-block;
  }
`

const ButtonsList = styled.div``

const StyledButton = styled(Button)`
  margin: 3vw auto 0 auto;

  @media (max-width: 991px) {
    margin: 20px auto 0 auto;
  }
`
