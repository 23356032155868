import styled from '@emotion/styled'
import { BlockBar } from 'app/components/BlockBar'
import { BlockCTA } from 'app/components/BlockCTA'
/* import { FlashNews } from 'app/components/FlashNews' */
import { GalleryBottom } from 'app/components/GalleryBottom'
import { GalleryRooms } from 'app/components/GalleryRooms'
import { GallerySlider } from 'app/components/GallerySlider'
import { HeroVideo } from 'app/components/HeroVideo'
import { HomeBlock } from 'app/components/HomeBlock'
import { Intro } from 'app/components/Intro'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Visual } from 'app/components/Visual'
import { ZigZags } from 'app/components/ZigZags'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          pageID={context.id}
          flashNewsProps={
            context.flashNewsProps ? context.flashNewsProps : null
          }
          {...context.headerProps}
        />
      ) : null}

      {context.videoProps ? (
        <HeroVideo variant="home" {...context.videoProps} />
      ) : null}
      {context.homeBlockProps ? (
        <HomeBlock {...context.homeBlockProps} />
      ) : null}
      {context.visualProps ? <Visual {...context.visualProps} /> : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.galleryRoomsProps ? (
        <GalleryRooms {...context.galleryRoomsProps} />
      ) : null}
      {context.zigZagsProps ? <ZigZags {...context.zigZagsProps} /> : null}
      {context.galleryFoodProps ? (
        <GallerySlider {...context.galleryFoodProps} />
      ) : null}
      {context.blockCTAProps ? (
        <BlockCTA variant="no-margin" {...context.blockCTAProps} />
      ) : null}
      {context.blockBarProps ? <BlockBar {...context.blockBarProps} /> : null}
      {context.galleryBottomProps ? (
        <GalleryBottom {...context.galleryBottomProps} />
      ) : null}
      {context.footerProps ? (
        <Footer languageCode={context.languageCode} {...context.footerProps} />
      ) : null}
    </Container>
  )
})

const Container = styled.main`
  .intro-block {
    margin: 8vw 0 6vw;

    @media (max-width: 1439px) {
      margin: 8vw 0 5vw;
    }

    .item-intro-right {
      margin-top: 6vw;

      @media (max-width: 991px) {
        margin-top: 60px;
      }
    }
    .item-intro-left {
      @media (max-width: 1439px) {
        padding: 6vw 7vw 0vw 16vw;
      }
      @media (max-width: 1199px) {
        padding: 6vw 4vw 0vw 16vw;
      }
      @media (max-width: 991px) {
        padding: 60px;
      }
    }
  }
`
