import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { FadeInZoom } from 'app/components/Common/Animation/FadeInZoom'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

export interface Props {
  imageLeft?: ImageProps
  imageRight?: ImageProps
  title?: string
  description?: string
}

export const HomeBlock = memo(function HomeBlock({
  imageLeft,
  imageRight,
  title,
  description,
}: Props) {
  return (
    <Container>
      <Wrapper className="wrapper-top">
        <TitleWrapper>
          <FadeInUp>
            {title ? (
              <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
            ) : null}
          </FadeInUp>
        </TitleWrapper>

        <ImageRightWrapper>
          <FadeInZoom>
            <ParallaxProvider>
              <ImageRight>
                <Parallax translateY={['-20%', '20%']}>
                  <Image {...imageRight} />
                </Parallax>
              </ImageRight>
            </ParallaxProvider>
          </FadeInZoom>
        </ImageRightWrapper>
      </Wrapper>

      <Wrapper className="wrapper-bottom">
        <ImageLeftWrapper>
          <FadeInZoom>
            <ParallaxProvider>
              <ImageLeft>
                <Parallax translateY={['-20%', '20%']}>
                  <Image {...imageLeft} />
                </Parallax>
              </ImageLeft>
            </ParallaxProvider>
          </FadeInZoom>
        </ImageLeftWrapper>

        <TextWrapper>
          <FadeInUp>
            {description ? (
              <Text dangerouslySetInnerHTML={{ __html: description }}></Text>
            ) : null}
          </FadeInUp>
        </TextWrapper>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  margin: 8vw 0;
  padding-left: 10vw;
  padding-right: 10vw;

  @media (max-width: 1199px) {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  @media (max-width: 991px) {
    margin: 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  &.wrapper-top {
    align-items: flex-end;
  }
  &.wrapper-bottom {
    align-items: center;
  }

  @media (max-width: 991px) {
    display: block;
  }
`
const TitleWrapper = styled.div`
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
  }
`

const Title = styled.h1`
  text-align: right;
  padding: 8vw 0;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  font-size: 2vw;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;

  strong {
    font-weight: 400;
    font-size: 3vw;
    font-family: ${({ theme }) => theme.fontFamily.heading};
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
    display: block;
  }

  @media (max-width: 991px) {
    font-size: 22px;
    padding: 60px 30px;
    width: 100%;

    strong {
      font-size: 28px;
    }
  }
`
const ImageRightWrapper = styled.div`
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
    overflow: hidden;
  }
`
const ImageRight = styled.div`
  padding-left: 8.611vw;
  aspect-ratio: 1.75;
  overflow: hidden;

  & > div {
    height: 100%;
  }
  img {
    width: auto;
    height: 100%;
    transform: scale(1.1);
  }

  @media (max-width: 991px) {
    display: none;
  }
`

const ImageLeftWrapper = styled.div`
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
    overflow: hidden;
  }
`
const ImageLeft = styled.div`
  overflow: hidden;
  aspect-ratio: 1.125;

  & > div {
    height: 100%;
  }
  img {
    width: auto;
    height: 100%;
    transform: scale(1.1);
  }
`

const TextWrapper = styled.div`
  padding-left: 8.611vw;
  padding-bottom: 0;
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
    padding: 30px;
  }
`

const Text = styled.div`
  width: 400px;
  max-width: 100%;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`
