import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

const AUTOPLAY_TIMER = 5000

interface ImagesProps {
  image?: ImageProps
}
export interface Props {
  title?: string
  subtitle?: string
  facebookURL?: string
  instagramURL?: string
  hero?: ImageProps
  images: ImagesProps[]
}

export const GalleryBottom = memo(function GalleryBottom({
  title,
  subtitle,
  facebookURL,
  instagramURL,
  hero,
  images,
}: Props) {
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    slides: { perView: 4 },
  })
  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = useState<number[]>([])
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])
  const timer = useRef<any>()

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <BackgroundWrapper>
        <Hero {...hero} />
      </BackgroundWrapper>
      <Wrapper>
        {title ? (
          <FadeInUp>
            <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
          </FadeInUp>
        ) : null}
        {subtitle ? (
          <FadeInUp>
            <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }}></Subtitle>
          </FadeInUp>
        ) : null}
        <FadeInUp>
          <Socials>
            {facebookURL ? (
              <a href={facebookURL} target="_blank">
                <img src="/icon_facebook.svg" />
              </a>
            ) : null}
            {instagramURL ? (
              <a href={instagramURL} target="_blank">
                <img src="/icon_instagram.svg" />
              </a>
            ) : null}
          </Socials>
        </FadeInUp>
        <Slider ref={sliderRef} className="keen-slider">
          {images.map((item, index) => (
            <Slide key={index} className="keen-slider__slide">
              <StyledImage {...item.image} />
            </Slide>
          ))}
        </Slider>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  margin-top: 15vw;

  @media (max-width: 991px) {
    margin-top: 60px;
  }
`
const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 35vw;
  overflow: hidden;

  &::after {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }
`
const Hero = styled(Image)`
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  img {
    width: 100%;
    height: auto;
  }
`

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 8vw 0;
`

const Title = styled.h2`
  font-size: 2.5vw;
  text-transform: uppercase;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 20px;
  }
`
const Subtitle = styled.h3`
  font-size: 13px;
  font-weight: 400;
  margin-top: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
`

const Socials = styled.div`
  text-align: center;
  margin-top: 2vw;

  img {
    height: 18px;
    width: auto;
    margin: 0 10px;
  }
`

const Slider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 4vw;

  @media (max-width: 991px) {
    margin-top: 10px;
  }
`

const Slide = styled.div`
  padding: 3.2vw;

  @media (max-width: 991px) {
    padding: 10px;
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
`
